//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-824:_9620,_3588,_6264,_6452,_6972,_1296,_5968,_8920;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-824')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-824', "_9620,_3588,_6264,_6452,_6972,_1296,_5968,_8920");
        }
      }catch (ex) {
        console.error(ex);
      }