//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-824:_2984,_300,_4252,_7516,_5020,_1692,_6188,_2808;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-824')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-824', "_2984,_300,_4252,_7516,_5020,_1692,_6188,_2808");
        }
      }catch (ex) {
        console.error(ex);
      }